// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v1.0',
  isMockEnabled: true,
  apiUrl: 'api',
  appThemeName: 'QRPAdmin',
  appPurchaseUrl: 'https://1.envato.market/EA4JP',
  appHTMLIntegration: 'https://preview.keenthemes.com/metronic8/demo7/documentation/base/helpers/flex-layouts.html',
  appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo7/',
  appPreviewAngularUrl: 'https://preview.keenthemes.com/metronic8/angular/demo7',
  appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
  appPreviewChangelogUrl: 'https://preview.keenthemes.com/metronic8/angular/docs/changelog',
  previewAvailable: true,
  donation_url: 'https://donate.staging.qr-pay.net',
  host_domain: 'https://api-staging.qr-pay.net',
  tenant_domain: 'api-staging.qr-pay.net',
  media_server: 'https://media.staging.qr-pay.net/',
  postcodeLookup: 'api.postcodes.io/postcodes/',
  httpProtocol: 'https://',
  recaptcha: {
    siteKey: '6Ld5SvciAAAAACstbwOGadwIBFxsv6DTjeYFhDkU',
  },
  googleMapsKey: 'AIzaSyD33nWk9C_PSlHe1EZkEI_M1Mi-CGCgBDw',
  stripe_public_key: 'pk_test_51Jtz1iClZcYCUJ5324uIgYqpiyBE3A62QStlsLXpFiPzURk7550WTRPmAHTyBt29bQhFC8ZeEuFw5rWh31wy35RQ00vUQlqTRV',
  clientIDs: {
    core: 1
  },
  currencyCodes: [
    {
      code: 'gbp',
      symbol: '£ (GBP)'
    }
  ],
  supportedCountries: [
    {
      name: 'United Kingdom',
      code: 'GB',
      currency: 'GBP'
    },
    {
      name: 'Ireland',
      code: 'IE',
      currency: 'EUR'
    },
    {
      name: 'USA',
      code: 'US',
      currency: 'USD'
    },
    {
      name: 'Australia',
      code: 'AU',
      currency: 'AUD'
    },
    {
      name: 'Canada',
      code: 'CA',
      currency: 'CAD'
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
